import React, { useState, useEffect } from 'react'

type AwardsTimerProps = {
  awardDates: string[]
}

const AwardsTimer: React.FC<AwardsTimerProps> = ({ awardDates }) => {
  const [countdown, setCountdown] = useState('')

  useEffect(() => {
    // Function to calculate the time remaining to the closest future date
    const calculateCountdown = () => {
      const now = new Date().getTime() // Get current time in milliseconds
      const futureDates = awardDates
        .map((dateStr) => new Date(dateStr).getTime())
        .filter((date) => date > now)

      if (futureDates.length > 0) {
        const closestDate = futureDates.reduce((a, b) => (a < b ? a : b))
        const timeRemaining = closestDate - now

        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
        const hours = Math.floor(
          (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        )
        const minutes = Math.floor(
          (timeRemaining % (1000 * 60 * 60)) / (1000 * 60),
        )
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

        let countdownString = ''
        if (days === 0) {
          countdownString = `${hours} godz. ${minutes} min ${seconds} s`
        } else {
          countdownString = `${days} dni ${hours} godz. ${minutes} min ${seconds} s`
        }

        setCountdown(countdownString)
      }
    }

    const interval = setInterval(calculateCountdown, 1000) // Update every second

    return () => clearInterval(interval)
  }, [awardDates])

  return <strong>{countdown}</strong>
}

export default AwardsTimer

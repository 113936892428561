export type GameConfigPayload = {
  difficulty: number
}

export enum GameConfigActionTypes {
  SET_GAME_CONFIG = 'SET_GAME_CONFIG',
}

export type GameConfigAction = {
  type: GameConfigActionTypes.SET_GAME_CONFIG
  payload: GameConfigPayload
}

export type GameImage = {
  content: string
  mimeType: string
  size: number
}

export type GameCardType = {
  id: number
  name: string
  img1: GameImage
  img2: GameImage | null
}

import React, { useContext, useEffect } from 'react'
import GameContext from '../GameContext'
import { Container, CardItem } from './styles'
import { GameCardType } from '../../../types/GameConfig'

const GameCards: React.FC = () => {
  const {
    firstSelectedCard,
    setFirstSelectedCard,
    secondSelectedCard,
    setSecondSelectedCard,
    iconFoundList,
    setIconFoundList,
    iconList,
    isPaused,
    isCheckingCards,
    setIsCheckingCards,
    difficulty,
  } = useContext(GameContext)

  const onSelectCard = (index: number) => (): void => {
    if (isCheckingCards) return
    if (firstSelectedCard === -1) setFirstSelectedCard(index)
    else setSecondSelectedCard(index)
  }

  const onEndCheckingSelectedCards = (): void => {
    setFirstSelectedCard(-1)
    setSecondSelectedCard(-1)
    setIsCheckingCards(false)
  }

  const onCheckIfFoundIcon = (): void => {
    if (firstSelectedCard === -1) return

    setIsCheckingCards(true)
    const firstSelectedCardIcon = iconList[firstSelectedCard]
    const secondSelectedCardIcon = iconList[secondSelectedCard]

    if (firstSelectedCardIcon === secondSelectedCardIcon) {
      setTimeout(() => {
        const iconFoundListClone = [...iconFoundList]
        iconFoundListClone.push(firstSelectedCardIcon)
        setIconFoundList(iconFoundListClone)
        onEndCheckingSelectedCards()
      }, 10)
    } else {
      setTimeout(onEndCheckingSelectedCards, 1000)
    }
  }

  useEffect(onCheckIfFoundIcon, [secondSelectedCard])

  const idCounts: { [key: string]: number } = {}

  return (
    <Container>
      {iconList.map(
        (icon: GameCardType, index: number): React.ReactNode => {
          const wasNotFound = iconFoundList.indexOf(icon) === -1
          const wasFound = iconFoundList.indexOf(icon) !== -1
          const isTheFirstSelectedCard = firstSelectedCard === index
          const isTheSecondSelectedCard = secondSelectedCard === index
          const onClick = onSelectCard(index)

          const isShowingFrontFace =
            isTheFirstSelectedCard || isTheSecondSelectedCard

          if (!idCounts[icon.id]) {
            idCounts[icon.id] = 0
          }
          idCounts[icon.id]++

          const imgToShow = idCounts[icon.id] % 2 === 0 ? 'img2' : 'img1'

          const numOfCardsInEachLine = difficulty / 4
          const isLastRow = index >= iconList.length - numOfCardsInEachLine

          return (
            <CardItem
              key={index}
              onClick={onClick}
              isVisible={wasNotFound}
              wasFound={wasFound}
              isShowingFrontFace={isShowingFrontFace}
              disabled={isPaused || isShowingFrontFace}
              numOfCardsInEachLine={difficulty / 4}
              isLastRow={isLastRow}
            >
              {imgToShow === 'img1' ? (
                <img
                  src={`data:${icon.img1.mimeType};base64,${icon.img1.content}`}
                  alt={icon.name}
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                />
              ) : (
                <img
                  src={
                    icon.img2
                      ? `data:${icon.img2.mimeType};base64,${icon.img2.content}`
                      : `data:${icon.img1.mimeType};base64,${icon.img1.content}`
                  }
                  alt={icon.name}
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                />
              )}
            </CardItem>
          )
        },
      )}
    </Container>
  )
}

export default GameCards

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import checked from '../../assets/checked.png'
import unchecked from '../../assets/unchecked.png'
import Loading from '../../components/Loading'

type VoteOptionImageProps = {
  id: number
  selected?: boolean
}

const VoteOptionImage: React.FC<VoteOptionImageProps> = ({
  id,
  selected = false,
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [imageData, setImageData] = useState<{
    imageContent: string
    imageMimeType: string
    size: number
  } | null>(null)

  useEffect(() => {
    axios
      .get(`https://erk-memo-api-t.tcreate.pl/api/main/vote-option/image/${id}`)
      .then((response) => {
        setImageData(response.data)
      })
      .catch((error) => {
        console.error(`Error fetching image for voteOption ${id}:`, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px',
        position: 'relative',
        minHeight: '200px'
      }}
    >
      {loading && <Loading />}
      {!loading && imageData && (
        <img
          src={`data:${imageData.imageMimeType};base64,${imageData.imageContent}`}
          alt=""
          style={{
            maxHeight: '280px',
            height: '100%',
            minWidth: '90px',
            boxShadow: selected ? '0px 0px 14px #C51431' : 'none',
          }}
        />
      )}
      <div style={{ position: 'absolute', left: '-20px', bottom: '-10px' }}>
        <img
          src={selected ? checked : unchecked}
          alt=""
        />
      </div>
    </div>
  )
}

export default VoteOptionImage

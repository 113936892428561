import React from 'react'
import styled from 'styled-components'

const LoadingSpinner = styled.div`
  .lds-dual-ring {
    display: inline-block;
    width: 40px;
    height: 40px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-radius: 50%;
    border: 3px solid #C51431;
    border-color: #C51431 transparent #C51431 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loading: React.FC = () => {
  return (
    <LoadingSpinner>
      <div className="lds-dual-ring"></div>
    </LoadingSpinner>
  )
}

export default Loading

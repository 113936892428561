import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`

export const VoteButton = styled.button`
  background: ${({ theme }): string => theme.accent};
  border-radius: 36px;
  padding: 8px 30px 12px 30px;
  border: 0;
  color: #fff;
  font-size: 20px;
  margin-top: -50px;
  z-index: 999;
`

export const SloganContainer = styled.div`
  text-align: right;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  img {
    height: 70px;
  }
  @media screen and (min-width: 390px) {
    font-size: 20px;
    img {
      height: 100px;
    }
  }
  @media screen and (min-width: 768px) {
    font-size: 28px;
    img {
      height: 100%;
    }
  }
`

export const StyledInput = styled.input`
  border: 1px solid #bebebe;
  border-radius: 30px;
  padding: 8px;
  text-align: center;
`

export const OutlinedButton = styled.button`
  border: 2px solid ${({ theme }): string => theme.accent};
  background: #fff;
  color: ${({ theme }): string => theme.accent};
  padding: 7px 14px;
  border-radius: 30px;
  cursor: pointer;
`

import React from 'react'
import { Container } from './styles'
import logo1x from '../../assets/erkado-logo.png'
import logo2x from '../../assets/erkado-logo@2x.png'
import { useHistory } from 'react-router-dom'
import HelpIcons from '../HelpIcons'

type HeaderProps = {
  children?: React.ReactNode
}

const Header: React.FC<HeaderProps> = (props) => {
  const history = useHistory()

  const onLogoClick = (): void => {
    history.push('/')
  }

  return (
    <Container>
      <img
        src={logo1x}
        srcSet={`${logo1x} 1x, ${logo2x} 2x`}
        alt=""
        style={{ maxWidth: '100%', height: '100%' }}
        onClick={onLogoClick}
      />
      {props.children && <div>{props.children}</div>}
      <div>
        <HelpIcons />
      </div>
    </Container>
  )
}

export default Header

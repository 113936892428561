import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { SecondaryAction } from './styles'
import AlertModal from '../../../components/AlertModal'
import useCheckGameEnd from '../useCheckGameEnd'
import GameContext from '../GameContext'

const GameWinMessage: React.FC = () => {
  const {
    isShowingWinModal,
    hoursText,
    minutesText,
    secondsText,
    milisecondsText,
  } = useContext(GameContext)

  const history = useHistory()
  useCheckGameEnd()

  const onGoBack = (): void => {
    history.goBack()
  }

  return (
    <AlertModal isShowing={isShowingWinModal}>
      <>
        <strong>GRATULACJE!</strong>
        <p>
          Twój wynik: {hoursText}:{minutesText}:{secondsText}.{milisecondsText}
        </p>
        <SecondaryAction onClick={onGoBack}>Powrót</SecondaryAction>
      </>
    </AlertModal>
  )
}

export default GameWinMessage

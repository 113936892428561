import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import SplashScreen from '../SplashScreen'
import GlobalStyles from '../../styles/Global'
import { useTypedSelector } from '../../hooks'
import THEME_COLORS from '../../styles/Theme'
import { ThemeTypes } from '../../types/Theme'
import Game from '../Game'
import Home from '../Home'
import Vote from '../Vote'
import Register from '../Register'
import Ranking from '../Ranking'

const App: React.FC = () => {
  const themeType = useTypedSelector(({ Theme }) => Theme.type)
  const themeColors = THEME_COLORS[themeType || ThemeTypes.dark]

  return (
    <ThemeProvider theme={themeColors}>
      <GlobalStyles />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/vote" component={Vote} />
        <Route path="/vote/:id" component={Vote} />
        <Route exact path="/splash" component={SplashScreen} />
        <Route exact path="/game" component={Game} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/ranking" component={Ranking} />
      </Switch>
    </ThemeProvider>
  )
}

export default App

import styled from 'styled-components'
import Card from '../../../components/Card'

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 65px 16px 0px 16px;
`

type CardItemType = {
  numOfCardsInEachLine: number
  isLastRow: boolean
}

export const CardItem = styled(Card)<CardItemType>`
  min-height: 100px;
  min-width: calc(
    (100% / ${({ numOfCardsInEachLine }): number => numOfCardsInEachLine}) - 8px
  );
  height: 20vh;
  font-size: 8rem;
  background: ${({ isLastRow }): string => (isLastRow ? '#c61230' : '#010101')};
  flex: 1 0
    calc(
      (100% / ${({ numOfCardsInEachLine }): number => numOfCardsInEachLine}) -
        16px
    );
`

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Container,
  Dialog,
  CloseButton,
  Inner,
} from './styles'

type AlertModalProps = {
  isShowing?: boolean
  onCloseModal?: () => void
  title?: string
  message?: string
  children?: React.ReactNode
}

const AlertModal: React.FC<AlertModalProps> = (props) => {
  const {
    isShowing = false,
    onCloseModal,
    children,
  } = props

  return (
    <Container isShowing={isShowing}>
      <Dialog>
        {onCloseModal && (
          <CloseButton onClick={onCloseModal}>
            x
          </CloseButton>
        )}
        <Inner>{children}</Inner>
      </Dialog>
    </Container>
  )
}

export default AlertModal

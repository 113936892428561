import { createGlobalStyle, css } from 'styled-components'
import bg from './../assets/bg.png'

const fontStyle = css`
  color: ${({ theme }): string => theme.primaryText};
  line-height: 1.5;
  font-size: 1.6rem;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: "univia-pro", sans-serif;
    background: #fff url(${bg}) no-repeat top center;
    background-attachment: fixed;
    background-size: cover;
    ${fontStyle}
  }

  html, body, #root {
    height: 100%;
  }

  textarea, input, button {
    font-family: "univia-pro", sans-serif;
    ${fontStyle}
  }
`

import React, { memo } from 'react'
import { Container } from './styles'

type CardProps = {
  children?: React.ReactNode
  onClick?: () => void
  isShowingFrontFace?: boolean
  isVisible?: boolean
  className?: string
  disabled?: boolean
  wasFound: boolean
}

const Card: React.FC<CardProps> = (props) => {
  const {
    children,
    onClick,
    isShowingFrontFace = false,
    isVisible = true,
    className,
    disabled = false,
    wasFound = false,
  } = props

  return (
    <Container
      isVisible={isVisible}
      className={className}
      isShowingFrontFace={isShowingFrontFace}
      onClick={onClick}
      disabled={disabled}
      wasFound={wasFound}
    >
      {isShowingFrontFace || wasFound ? children : ''}
    </Container>
  )
}

export default memo(Card)

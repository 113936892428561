import React, { useState } from 'react'
import styled from 'styled-components'
// import AgreementModal from '../modals/AgreementModal'

export interface Agreement {
  id: number
  code: string
  isRequired: boolean
  name: string | null
  description: string | null
  url: string | null
  engName: string | null
  engDescription: string | null
  engUrl: string | null
  checked: boolean
}

interface AgreementCheckboxesProps {
  agreements: Agreement[]
  onAgreementChange: (agreement: Agreement, isChecked: boolean) => void
}

const Container = styled.div`
  margin: 15px 0;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const CheckboxLabel = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 11px;
`

const StyledCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 0px;
  cursor: pointer;
  position: relative;
  margin-right: 8px;
  margin-top: 0px;
  background: #fff;

  &:checked {
    border-color: #C51431;
    background-color: #C51431;
  }

  &:checked::before {
    content: '\u2714';
    font-size: 12px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const AgreementCheckboxes: React.FC<AgreementCheckboxesProps> = ({
  agreements,
  onAgreementChange,
}) => {
  const [isAllSelected, setIsAllSelected] = useState(false)
  // const [activeAgreement, setActiveAgreement] = useState<Agreement | null>(null)
  // const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false)
  const [individualAgreements, setIndividualAgreements] = useState(
    agreements.map((agreement) => ({
      ...agreement,
      checked: false,
    })),
  )

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    agreement: Agreement,
  ) => {
    const { checked } = e.target
    if (agreement.code === 'all') {
      setIsAllSelected(checked)
      setIndividualAgreements(
        individualAgreements.map((agreement) => ({ ...agreement, checked })),
      )
      agreements.forEach((agreement) => onAgreementChange(agreement, checked))
    } else {
      const updatedIndividualAgreements = individualAgreements.map((agmt) =>
        agmt.code === agreement.code ? { ...agmt, checked } : agmt,
      )
      setIndividualAgreements(updatedIndividualAgreements)

      if (updatedIndividualAgreements.every((agmt) => agmt.checked)) {
        setIsAllSelected(true)
      } else if (updatedIndividualAgreements.some((agmt) => !agmt.checked)) {
        setIsAllSelected(false)
      }

      onAgreementChange(agreement, checked)
    }
  }

  // const renderShowMoreButton = (agreement: Agreement) => {
  //   return (
  //     <>
  //       <br />
  //       <div
  //         style={{ display: 'inline-block', cursor: 'pointer' }}
  //         onClick={() => {
  //           setActiveAgreement(agreement)
  //           setIsAgreementModalOpen(true)
  //         }}
  //       >
  //         Pokaż więcej
  //       </div>
  //     </>
  //   )
  // }

  // const renderUrlLink = (agreement: Agreement) => {
  //   return (
  //     <>
  //       <br />
  //       <a
  //         href={agreement.url || ''}
  //         target="_blank"
  //         style={{ color: '#C51431', textDecoration: 'underline' }}
  //         rel="noreferrer"
  //       >
  //         {agreement.linkLabel}
  //       </a>
  //     </>
  //   )
  // }

  return (
    <Container>
      <CheckboxContainer>
        <StyledCheckbox
          type="checkbox"
          name="all"
          id="agreement-all"
          checked={isAllSelected}
          onChange={(e) =>
            handleCheckboxChange(e, {
              id: 0,
              code: 'all',
              name: 'Zaznacz wszystkie',
              checked: e.target.checked,
              isRequired: false,
              description: null,
              url: null,
              engName: null,
              engDescription: null,
              engUrl: null
            })
          }
        />
        <CheckboxLabel htmlFor='agreement-all'>Zaznacz wszystkie</CheckboxLabel>
      </CheckboxContainer>
      {individualAgreements.map((agreement) => (
        <CheckboxContainer key={agreement.code}>
          <StyledCheckbox
            type="checkbox"
            name={agreement.code}
            checked={agreement.checked}
            onChange={(e) => handleCheckboxChange(e, agreement)}
            id={`agreement-${agreement.code}`}
          />{' '}
          <CheckboxLabel htmlFor={`agreement-${agreement.code}`} style={{ width: 'calc(100% - 30px)' }}>
            {agreement.description} {agreement.isRequired && '*'}
            {/* {agreement.url && agreement.linkLabel && renderUrlLink(agreement)} */}
            {/* {agreement.description && renderShowMoreButton(agreement)} */}
          </CheckboxLabel>
        </CheckboxContainer>
      ))}

      {/* {isAgreementModalOpen && activeAgreement && (
        <AgreementModal
          onClose={() => setIsAgreementModalOpen(false)}
          description={activeAgreement.description || ''}
        />
      )} */}
    </Container>
  )
}

export default AgreementCheckboxes

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`

export const RankingItem = styled.div`
  border: 1px solid #bebebe;
  border-radius: 30px;
  padding: 9px 14px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  width: 80%;
  max-width: 600px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
`

export const OutlinedButton = styled.button`
  border: 2px solid ${({ theme }): string => theme.accent};
  background: #fff;
  color: ${({ theme }): string => theme.accent};
  padding: 9px 14px;
  border-radius: 30px;
  cursor: pointer;
  &:disabled {
    color: #ccc;
    border-color: #ccc;
  }
`


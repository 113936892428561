import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`

export const VoteButton = styled.button`
  background: ${({ theme }): string => theme.accent};
  border-radius: 36px;
  padding: 8px 30px 12px 30px;
  border: 0;
  color: #fff;
  font-size: 20px;
  margin-top: -50px;
  z-index: 999;
`

export const SloganContainer = styled.div`
  text-align: right;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  img {
    height: 70px;
  }
  @media screen and (min-width: 390px) {
    font-size: 22px;
    img {
      height: 100px;
    }
  }
  @media screen and (min-width: 768px) {
    font-size: 28px;
    img {
      height: 100%;
    }
  }
`

export const StyledInput = styled.input`
  border: 1px solid #bebebe;
  border-radius: 30px;
  padding: 8px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
`

export const OutlinedButton = styled.button`
  border: 2px solid ${({ theme }): string => theme.accent};
  background: #fff;
  color: ${({ theme }): string => theme.accent};
  padding: 9px 14px;
  border-radius: 30px;
  cursor: pointer;
  &:disabled {
    color: #ccc;
    border-color: #ccc;
  }
`

export const NormalButton = styled.button`
  border: 2px solid ${({ theme }): string => theme.accent};
  background: ${({ theme }): string => theme.accent};
  color: #fff;
  padding: 9px 14px;
  border-radius: 30px;
  cursor: pointer;
  width: 80%;
  max-width: 600px;
`

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: 8px;
  font-family: 'univia-pro', sans-serif;
  color: #313131;
  line-height: 1.5;
  font-size: 1.6rem;
  border-radius: 30px;
  text-align: center;
  text-align: -webkit-center;
  text-align-last: center;
  background: #fff;
  border: 1px solid #bebebe;
  margin-bottom: 10px;
`

export const FormInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  @media (min-width: 500px) {
    justify-content: space-between; /* Two columns, evenly spaced */

    input, select {
      width: 48%; /* Adjust the width of input fields for two columns */
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  @media (min-width: 500px) {
    width: 48%;

    input, select {
      width: 100%; /* Adjust the width of input fields for two columns */
    }
  }
  input {
    margin-bottom: 5px;
  }
`

import styled from 'styled-components'
import voteBg from '../../assets/vote-bg.png'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 800px;
  margin-bottom: 100px;
`

export const GridItem = styled.div`
  width: calc(
    25% - 40px
  ); /* Adjust the width as needed and account for margin */
  margin: 20px; /* Add margin for spacing between items */
  cursor: pointer;
  text-align: center;
  font-size: 14px;

  @media (max-width: 450px) {
    width: calc(
      33.33% - 40px
    ); /* 3 columns when screen width is less than or equal to 1200px */
  }

  @media (max-width: 350px) {
    width: calc(
      50% - 40px
    ); /* 2 columns when screen width is less than or equal to 900px */
  }

  @media (max-width: 250px) {
    width: calc(
      100% - 40px
    ); /* 1 column when screen width is less than or equal to 600px */
  }
`

export const VoteButtonContainer = styled.div`
  width: 100%;
  background: url(${voteBg}) repeat-x top left;
  position: fixed;
  height: 100px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VoteButton = styled.button`
  background: ${({ theme }): string => theme.accent};
  border-radius: 36px;
  padding: 10px 30px 15px 30px;
  border: 0;
  color: #fff;
  font-size: 20px;
  width: 60%;
  max-width: 600px;
  &:disabled {
    background: #8F8F8F;
    color: #686868;
  }
`
